import * as React from 'react';
import { graphql } from 'gatsby';
import {
   Layout,
   Hero,
   Seo,
   PageSubtitle,
   Services,
   About,
   ImageSection,
   Culture,
   CallToAction,
} from '../components';

const Home = ({ data }) => {
   const {
      heroImage,
      heroHeading,
      heroDescription,
      centerImage,
      slides,
      aboutUsImg,
      aboutUsTitle,
      aboutUsDesc,
   } = data.datoCmsHomepage;

   return (
      <Layout>
         <Seo
            title='Home'
            description='Barcode Buildup is a Woman Owned premium freight service company headquartered in Boca Raton, FL. We offer exceptional transportation and logistics services at competitive rates because we bring an alliance carrier network with time tested proficiency in the industry.'
         />
         <Hero heroTitle={heroHeading} heroImage={heroImage} />
         <Services services={data.datoCmsServicesPage.service} />
         <PageSubtitle subtitle={heroDescription} center='true' />
         <ImageSection img={centerImage} />
         <About img={aboutUsImg} subtitle={aboutUsTitle} desc={aboutUsDesc} />
         <Culture slides={slides} />
         <CallToAction dark='true' address='/contact' />
      </Layout>
   );
};

export default Home;

export const query = graphql`
   {
      datoCmsHomepage {
         heroImage {
            gatsbyImageData(layout: FULL_WIDTH)
         }
         heroDescription
         heroHeading {
            id
            listItem
         }
         slides: slideItem {
            id
            image {
               gatsbyImageData
            }
            title
            desc {
               value
            }
         }

         aboutUsImg {
            gatsbyImageData(layout: FULL_WIDTH)
         }
         aboutUsTitle
         aboutUsDesc {
            value
         }
      }

      datoCmsServicesPage {
         service {
            id
            serviceTitle
            serviceSlug
            serviceDesc
            serviceImage {
               gatsbyImageData(layout: FULL_WIDTH)
            }
         }
      }
   }
`;
